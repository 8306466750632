var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-select",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.readOnly || _vm.disabled,
                  multiple: _vm.multiple,
                  placeholder: "请选择",
                },
                model: {
                  value: _vm.selectValue,
                  callback: function ($$v) {
                    _vm.selectValue = $$v
                  },
                  expression: "selectValue",
                },
              },
              _vm._l(_vm.$items, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: {
                    label: item.text,
                    value: item.value,
                    disabled: item.disabled,
                  },
                })
              }),
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }