var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "statistics-panle nopaddingd" },
    [
      [
        _c("div", { staticClass: "asq-question-name" }, [
          _c("div", { staticClass: "asq-question-title" }, [
            _c("span", { staticClass: "asq-question-type" }, [
              _vm._v("【" + _vm._s(_vm.asqKeyNames[_vm.questionType]) + "】"),
            ]),
            _vm._v(" " + _vm._s(_vm.it.name) + " "),
          ]),
          _vm.questionType !== "dsf.asqquestionhorzfill"
            ? _c("div", { staticClass: "asq-operation-icon" }, [
                _vm.showWays[_vm.questionType].includes(1)
                  ? _c("i", {
                      staticClass: "iconfont icon-tubiao-zhuzhuangtu",
                      class: { select: _vm.status === 1 },
                      on: {
                        click: function ($event) {
                          _vm.status = 1
                        },
                      },
                    })
                  : _vm._e(),
                _vm.showWays[_vm.questionType].includes(2)
                  ? _c("i", {
                      staticClass: "iconfont icon-tongjifenxi1",
                      class: { select: _vm.status === 2 },
                      on: {
                        click: function ($event) {
                          _vm.status = 2
                        },
                      },
                    })
                  : _vm._e(),
                _vm.showWays[_vm.questionType].includes(3)
                  ? _c("i", {
                      staticClass: "iconfont icon-zhexiantu",
                      class: { select: _vm.status === 3 },
                      on: {
                        click: function ($event) {
                          _vm.status = 3
                        },
                      },
                    })
                  : _vm._e(),
                _vm.showWays[_vm.questionType].includes(4)
                  ? _c("i", {
                      staticClass: "iconfont icon-left-alignment",
                      class: { select: _vm.status === 4 },
                      on: {
                        click: function ($event) {
                          _vm.status = 4
                        },
                      },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ],
      _c(
        "div",
        { staticClass: "asq-statistics-question" },
        [
          _vm.status === 1
            ? _c(
                "div",
                { staticClass: "asq-statistics-chart" },
                [
                  _c("DsfPlatformBar", {
                    ref: "chart",
                    attrs: { chart: _vm.barChart },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.status === 2
            ? _c(
                "div",
                { staticClass: "asq-statistics-chart" },
                [
                  _c("DsfPlatformPie", {
                    ref: "chart",
                    attrs: { chart: _vm.pieChart },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.status === 3
            ? _c(
                "div",
                { staticClass: "asq-statistics-chart" },
                [
                  _c("DsfPlatformPolyline", {
                    ref: "chart",
                    attrs: { chart: _vm.lineChart },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.status === 4
            ? _c(
                "div",
                { staticClass: "asq-statistics-chart" },
                [
                  _c("DsfPlatformBar", {
                    ref: "chart",
                    attrs: { chart: _vm.barVerChart },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.questionType === "dsf.asqquestionfill"
            ? [
                _c(
                  "div",
                  { staticClass: "asq-statistics-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.commonTable,
                          "highlight-current-row": false,
                          "cell-style": { color: "#262F35" },
                          "header-cell-class-name": "head-bg",
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            label: "序号",
                            align: "center",
                            width: "70",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "content", label: "回答详情" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.questionType === "dsf.asqquestionhorzfill"
            ? [
                _c(
                  "div",
                  { staticClass: "asq-statistics-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.fills.length > 0 ? _vm.fillTable : [],
                          "highlight-current-row": false,
                          "cell-style": { color: "#262F35" },
                          "header-cell-class-name": "head-bg",
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            label: "序号",
                            align: "center",
                            width: _vm.fills.length > 0 ? 70 : "843",
                          },
                        }),
                        _vm._l(_vm.fills, function (item, index) {
                          return _c("el-table-column", {
                            key: index,
                            attrs: { label: "填空" + (index + 1) },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.row.fills[index]) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm.questionType === "dsf.asqquestionorder"
            ? [
                _c(
                  "div",
                  { staticClass: "asq-statistics-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.fillTable,
                          "highlight-current-row": false,
                          "cell-style": { color: "#262F35" },
                          "header-cell-class-name": "head-bg",
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "选项", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ellipsis",
                                      attrs: { title: scope.row.text },
                                    },
                                    [
                                      _vm._v(
                                        "选项" +
                                          _vm._s(scope.$index + 1) +
                                          " ： " +
                                          _vm._s(scope.row.text)
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._l(_vm.it.options, function (item, index) {
                          return _c("el-table-column", {
                            key: index,
                            attrs: { label: "第" + (index + 1) + "位" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.getRank(scope, index)) +
                                          "% "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "avg", label: "平均排名" },
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            : _vm.questionType === "dsf.asqquestionscore" ||
              _vm.questionType === "dsf.asqquestionproportion"
            ? [
                _c(
                  "div",
                  { staticClass: "asq-statistics-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.scoreTabel,
                          "highlight-current-row": false,
                          "cell-style": { color: "#262F35" },
                          "header-cell-class-name": "head-bg",
                          border: "",
                          fit: true,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "选项", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "ellipsis" }, [
                                    _vm._v(
                                      "选项" +
                                        _vm._s(scope.$index + 1) +
                                        " ： " +
                                        _vm._s(scope.row.text)
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label:
                              _vm.questionType === "dsf.asqquestionscore"
                                ? "最高分"
                                : "最高占比",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    _vm._s(scope.row.max || 0) +
                                      _vm._s(
                                        _vm.questionType ===
                                          "dsf.asqquestionscore"
                                          ? "分"
                                          : "%"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "min",
                            label:
                              _vm.questionType === "dsf.asqquestionscore"
                                ? "最低分"
                                : "最低占比",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.min || 0) +
                                      _vm._s(
                                        _vm.questionType ===
                                          "dsf.asqquestionscore"
                                          ? "分"
                                          : "%"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "avg",
                            label:
                              _vm.questionType === "dsf.asqquestionscore"
                                ? "平均分数"
                                : "平均占比",
                            width: "120",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.avg || 0) +
                                      _vm._s(
                                        _vm.questionType ===
                                          "dsf.asqquestionscore"
                                          ? "分"
                                          : "%"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm.questionType === "dsf.asqquestionsignature"
            ? [
                _c(
                  "div",
                  { staticClass: "asq-statistics-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.commonTable,
                          "highlight-current-row": false,
                          "cell-style": { color: "#262F35" },
                          "header-cell-class-name": "head-bg",
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            label: "序号",
                            align: "center",
                            width: "70",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: { label: "签名" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.files
                                    ? _c("el-image", {
                                        ref: "preImage" + scope.$index,
                                        staticStyle: { width: "180px" },
                                        attrs: {
                                          "preview-src-list": [
                                            _vm.getSignature(scope),
                                          ],
                                          src: _vm.getSignature(scope),
                                          fit: "scale-down",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("div", { staticClass: "opeartion-btns" }, [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.preView($event, scope)
                                          },
                                        },
                                      },
                                      [_vm._v("预览")]
                                    ),
                                    _c("span", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            download: "",
                                            href: _vm.getSignature(scope),
                                          },
                                        },
                                        [_vm._v("下载")]
                                      ),
                                    ]),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "asq-statistics-table" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.commonTable,
                          "highlight-current-row": false,
                          "cell-style": { color: "#262F35" },
                          "header-cell-style": { background: " #f5f7fa" },
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "选项" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.img
                                    ? [
                                        _vm._v(
                                          " 选项" +
                                            _vm._s(scope.$index + 1) +
                                            " ： "
                                        ),
                                        scope.row.img
                                          ? _c("el-image", {
                                              staticStyle: { width: "80px" },
                                              attrs: {
                                                "preview-src-list": [
                                                  scope.row.img,
                                                ],
                                                src: scope.row.img,
                                                fit: "scale-down",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(
                                          " " + _vm._s(scope.row.text) + " "
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "ellipsis",
                                            attrs: { title: scope.row.text },
                                          },
                                          [
                                            _vm._v(
                                              "选项" +
                                                _vm._s(scope.$index + 1) +
                                                " ： " +
                                                _vm._s(scope.row.text)
                                            ),
                                          ]
                                        ),
                                      ],
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "rate",
                            label: "百分比",
                            width: "270",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "chooseNum",
                            label: "响应数",
                            width: "110",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }