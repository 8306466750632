<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-01-07 16:22:20
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-14 13:55:05
-->
<template>
  <DsfAsqQuestionBody class="ds-question-checkbox" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <el-cascader
        ref="cascader"
        v-model="selectValue"
        :options="items"
        :props="props"
        :disabled="isDesign || readOnly || disabled"
        placeholder="请选择"
      />
    </template>
  </DsfAsqQuestionBody>
</template>

<script>
export default dsf.component({
  name: "DsfAsqQuestionCascader",
  ctrlCaption: "级联题",
  mixins: [$mixins.asqQuestionCascaderMixins],
  props: {
    pickHeight: {
      type:String,
      default: '60%'
    }
  },
  computed: {
    props() {
      return {
        multiple: this.multiple,
        expandTrigger: 'click',
        value: 'value',
        label: 'text',
        children: 'children'
      }
    },
    selectValue: {
      get() {
        let val = this.value;
        if (dsf.isUnDef(val) || dsf.isEmptyObject(val)) {
          return this.multiple ? [] : null;
        }
        if (this.multiple) {
          if (dsf.isArray(val)) {
            return val.map((v) => this.getValuePath(v.value));
          } else if (dsf.isObject(val)) {
            return [this.getValuePath(val.value)];
          } else {
            return [];
          }
        } else {
          if (dsf.isArray(val)) {
            return this.getValuePath(val[0].value);
          } else if (dsf.isObject(val)) {
            return this.getValuePath(val.value);
          } else {
            return null;
          }
        }
      },
      set(v) {
        this.isDesign || this.changeValue(v);
      }
    }
  },
  methods: {
    changeValue(to) {

      if (!to.length) {
        this.updateValue();
        return;
      }
      let nodes = this.$refs.cascader.getCheckedNodes();
      let fn1 = v => {
        let node = _.find(nodes, n => n.value == _.last(v));
        let res = [];
        while (node) {
          res.unshift(node.data);
          node = node.parent;
        }
        return res;
      };
      let fn2 = arr => {
        let text = [];
        let value = _.map(arr, it => {
          text.push(it.text);
          return it.value;
        });
        return {text: text.join(' / '), value: value.pop()};
      };
      if (dsf.isArray(to[0])) {
        this.updateValue(_.map(to, it => fn2(fn1(_.last(it)))));
        return;
      }
      this.updateValue([fn2(fn1(to))]);
    }
  }
});
</script>