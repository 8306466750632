var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-checkbox",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c("el-cascader", {
              ref: "cascader",
              attrs: {
                options: _vm.items,
                props: _vm.props,
                disabled: _vm.isDesign || _vm.readOnly || _vm.disabled,
                placeholder: "请选择",
              },
              model: {
                value: _vm.selectValue,
                callback: function ($$v) {
                  _vm.selectValue = $$v
                },
                expression: "selectValue",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }