<!--
 * @Descripttion: 报表组件
 * @Author: zhanghang
 * @Date: 2022-01-25 10:30:39
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-22 10:17:57
-->
<template>
  <div class="ds-control asq-report-form">
    <div class="search-condition">
      <p>
        <span @click="showCondition = !showCondition">
          <i class="iconfont icon-icon_filter" />
          <span>筛选条件</span>
          <i :class="{ 'show-condition': showCondition }" class="search-icon el-icon-arrow-down"></i>
        </span>
        <span>
          <DsfButton type="plain" @click="downLoadPdf">下载PDF</DsfButton>
          <DsfButton type="plain" @click="openSetting">显示设置</DsfButton>
        </span>
      </p>
      <div :class="{ show: showCondition }" class="condition-box">
        <el-form :inline="true" :model="conditions">
          <el-form-item label="答题设备">
            <el-select v-model="conditions.deviceType" placeholder="请选择">
              <el-option label="电脑端" value="pc"></el-option>
              <el-option label="移动端" value="mobile"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="调查对象">
            <el-select v-model="conditions.respondentId" placeholder="请选择">
              <el-option v-for="it in opts" :key="it.value" :label="it.text" :value="it.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="middle" label="提交时间">
            <el-date-picker
              v-model="conditions.submit_datetime"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item class="small" label="题目名称">
            <el-input v-model="conditions.keywords" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <DsfButton @click="initData">查询</DsfButton>
            <DsfButton type="plain" @click="reset">重置</DsfButton>
          </el-form-item>
        </el-form>
        <div class="arrow"></div>
      </div>
    </div>
    <div class="statistics-panle-wrap">
      <el-empty style="background: #fff" v-if="!(questions && questions.length) && isLoad" description="未查询到数据"></el-empty>
      <DsfAsqQuestionStatisticsPanle :showType="porpsType" :it="it" v-for="(it, index) in questions" :questionType="it.type" :key="index" />
    </div>
  </div>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionReportForm',
  ctrlCaption: '问卷统计报表',
  mixins: [$mixins.control],
  data() {
    return {
      id: '',
      questions: [],
      //是否展示筛选条件
      showCondition: true,
      isDownload: false,
      isLoad: false,
      opts: [],
      //图形展示方式
      showType:{
        value:-1
      },
      porpsType:-1,

      conditions: {
        deviceType: '',
        respondentId: '',
        submit_datetime: '',
        keywords: ''
      }
    }
  },
  created() {
    this.id = this.queryString.id
    if (!this.isDesign) {
      this.init()
      //初始化数据
      this.initData()
    }
    this.loadScript(() => {
      this.isDownload = true
    })
  },
  methods: {
    init() {
      this.$http
        .get('/asq/asq-info/batch/respondent/getRespondent', { asqInfoId: this.id }, null, $$webRoot.default)
        .done(({ success, data, message }) => {
          if (success) {
            this.opts = data
          } else {
            dsf.layer.message(message, false)
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false)
        })
        .always(() => {})
    },
    //下载pdf
    async downLoadPdf() {
      let base64 = await this.downLoad()
      if (!base64) return
      let url = dsf.url.getWebPath(`/asq/asq-info/downStatistics`, $$webRoot.default)
      $(
        `<form action="${url}" method="post"><input type="text" name="id" value="${this.id}"/><input type="text" name="img" value="${
          base64.split(',')[1]
        }"/></form>`
      )
        .appendTo('body')
        .submit()
        .remove()
    },
   
    //打开显示设置
    //  status 0没图表 1 柱状图 2 饼状图 3 折现图 4条形图
    openSetting() {
      let _self = this
      this.$openDialog({
        title: '显示设置',
        template: `
        <div>
          <p>显示方式</p>
          <br/>
          <el-radio-group v-model="params.showType.value">
          <el-radio :label="-1">默认</el-radio>
          <el-radio :label="2">饼状</el-radio>
          <el-radio :label="1">柱状</el-radio>
          <el-radio :label="3">折线</el-radio>
          <el-radio :label="4">条形</el-radio>
          </el-radio-group>
        </div>
        `,
        width: '430px',
        height: '80px',
        params:{
         showType:_self.showType
        },
        btns: [
          {
            text: '确定',
            handler:() =>{
              this.porpsType = this.showType.value
            }
          },
          {
            text: '取消'
          }
        ]
      })
    },
    reset() {
      this.conditions.deviceType = ''
      this.conditions.submit_datetime = ''
      this.conditions.keywords = ''
      this.conditions.respondentId = ''
    },
    //初始化数据
    initData() {
      let { submit_datetime, ...items } = this.conditions
      let startDate = submit_datetime ? submit_datetime[0] : ''
      let endDate = submit_datetime ? submit_datetime[1] : ''
      this.$http
        .get('/asq/asq-info/queryStatistics', { id: this.id, ...items, startDate, endDate }, null, $$webRoot.default)
        .done(({ success, data, message }) => {
          if (success) {
            this.questions = data.questions
            this.$dispatch('getData', data)
          } else {
            dsf.layer.message(message, false)
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false)
        })
        .always(() => {
          this.isLoad = true
        })
    },
    // 下载报表统计
    downLoad() {
      if (!this.isDownload) return false
      return html2canvas(document.querySelector('.statistics-panle-wrap'), {
        ignoreElements: function (el) {
          if (el.className === 'asq-operation-icon') {
            return true
          }
        }
      }).then(function (canvas) {
        return canvas.toDataURL()
      })
    },
    loadScript(callback) {
      if (window.html2canvas) {
        callback?.()
      } else {
        this.$http
          .importFiles([dsf.url.getWebPath('$/js/libs/html2canvas/html2canvas.min.js')])
          .then(() => {
            callback?.()
          })
          .catch((err) => {
            console.error(err)
            dsf.layer.message('加载html2canvas文件报错', false)
          })
      }
    }
  }
})
</script>
