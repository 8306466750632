var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ds-control ds-page",
      class: _vm.getCss,
      style: _vm.pageStyle,
      attrs: { "page-type": _vm.pageType },
    },
    [
      _c(
        "div",
        {
          ref: "content",
          staticClass: "ds-page-content",
          style: _vm.getBackgroundStyle,
        },
        [
          _c(
            "div",
            {
              staticClass: "ds-page-content-wrap",
              style: _vm.pageContentStyle,
            },
            [
              _c(
                "div",
                { staticClass: "ds-question-page-welcome" },
                [
                  _vm.isDesign
                    ? [
                        _c("DsfQuestionDesignerEditor", {
                          staticClass: "welcome-text",
                          attrs: {
                            value: _vm.welcomeText,
                            align: _vm.textAlign,
                            placeholder: "调查问卷",
                            "empty-use-placeholder": "",
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.welcomeText = $event
                            },
                          },
                        }),
                        !_vm.vaildName
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "red",
                                  "text-align": "center",
                                },
                              },
                              [
                                _vm._v(
                                  "限制字数在0-" + _vm._s(_vm.nameLimit) + "内"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("DsfQuestionDesignerEditor", {
                          staticClass: "welcome-sub-text",
                          attrs: {
                            value: _vm.welcomeSubText,
                            placeholder: "欢迎使用",
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.welcomeSubText = $event
                            },
                          },
                        }),
                      ]
                    : [
                        _c("div", {
                          staticClass: "welcome-text",
                          style: { "text-align": _vm.textAlign },
                          domProps: { innerHTML: _vm._s(_vm.welcomeText) },
                        }),
                        _c("div", {
                          staticClass: "welcome-sub-text",
                          domProps: { innerHTML: _vm._s(_vm.welcomeSubText) },
                        }),
                        _c("div", { staticClass: "welcome-split" }),
                      ],
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "ds-page-center",
                  style: _vm.contentStyle,
                  attrs: { "slot-name": _vm.slots[0].name },
                },
                [
                  _vm._t(_vm.slots[0].name, function () {
                    return [
                      _vm.isDesign && _vm.slots[0].controls.length <= 0
                        ? _c("span", { staticClass: "empty-title" }, [
                            _vm._v("将题型拖到此区域"),
                          ])
                        : _vm._e(),
                    ]
                  }),
                  !_vm.isDesign
                    ? _c(
                        "div",
                        { staticClass: "ds-question-buttons" },
                        [
                          _c("dsf-asq-question-buttons", {
                            attrs: { isPreView: _vm.isPreView },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.isDesign
                ? _c("div", { staticClass: "designer-question-import" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.importHandler()
                          },
                        },
                      },
                      [_vm._v("导入题目")]
                    ),
                  ])
                : _vm._e(),
              _vm.isDesign
                ? _c(
                    "div",
                    { staticClass: "ds-question-page-complete" },
                    [
                      _c("DsfQuestionDesignerEditor", {
                        staticClass: "complete-text",
                        attrs: { align: "center", value: _vm.completeText },
                        on: {
                          "update:value": function ($event) {
                            _vm.completeText = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c("div", {
        staticStyle: { display: "none" },
        domProps: { innerHTML: _vm._s(_vm.customCss) },
      }),
      _vm.gotoIcon && !_vm.isPreView
        ? _c("dsf-to-designer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isDesign,
                expression: "!isDesign",
              },
            ],
            ref: "eleDesgin",
            attrs: { title: _vm.title, "goto-preview": _vm.gotoPreview },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }