var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-radio",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "ds-question-options",
                class: ["layout--" + _vm.optionsLayout],
              },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "el-row el-row--flex",
                    attrs: { disabled: _vm.readOnly || _vm.disabled },
                    model: {
                      value: _vm.selectValue,
                      callback: function ($$v) {
                        _vm.selectValue = $$v
                      },
                      expression: "selectValue",
                    },
                  },
                  _vm._l(_vm.$items, function (item) {
                    return _c(
                      "el-Radio",
                      {
                        key: item.value,
                        class: ["el-col", "el-col-" + 24 / _vm.optionsLayout],
                        attrs: { label: item.value, disabled: item.disabled },
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }