/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-15 10:50:17
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-12 17:20:28
 */

export default dsf.component({
  name: "DsfAsqQuestionGroupTitle",
  ctrlCaption: "分组标题",
  mixins: [$components.platform.DsfTitle],
  props:{
    isGroupTitle:{
      type:Boolean,
      default:true
    },
    fontType:{
      type:Number,
      default:3
    },
    showWeight:{
      type:Boolean,
      default:true
    }
  }
});