var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-proportion",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c("div", { staticClass: "ds-question-proportion-options" }, [
              _vm.isLimitTotal
                ? _c("p", [
                    _c("span", [_vm._v("比重总和：")]),
                    _vm.proporty && _vm.value
                      ? _c("span", [_vm._v(_vm._s(_vm.proporty) + " /")])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.total) + " "),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "proportion-list" },
                _vm._l(_vm.valueCopy, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "proportion-list-item" },
                    [
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(item.text) },
                        }),
                      ],
                      _c("Slider", {
                        attrs: {
                          isDesign: _vm.isDesign,
                          readOnly: _vm.readOnly,
                          disabled: _vm.disabled,
                          isShowTooltip: _vm.isShowTooltip,
                          marks: _vm.marks,
                          min: _vm.minValue,
                          isLimitTotal: _vm.isLimitTotal,
                          isMutual:
                            _vm.isLimitTotal && _vm.valueCopy.length === 2,
                          index: index,
                          limit: _vm.maxValue,
                          carryover: _vm.carryover,
                          value: item.score,
                          max: _vm.maxValue,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(item, "score", $event)
                          },
                          mutual: _vm.mutual,
                        },
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }