<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-17 16:48:46
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-24 16:02:58
-->
<template>
  <div class="ds-control ds-question-item">
    <div class="ds-question-item-wrap" v-if="!question.logicIgnore">
      <div class="ds-question-index">
        <span class="required"></span>
        <span class="ds-question-index-num" v-show="$showIndex">{{ question.$questionIndex }}</span>
      </div>
      <div class="ds-question-content">
        <div class="ds-question-stem">
          <template v-if="!isDesign">
            <div v-html="question.stem"></div>
          </template>
          <template v-else>
            <DsfQuestionDesignerEditor :value.sync="question.stem" :placeholder="'请输入题干'" empty-use-placeholder></DsfQuestionDesignerEditor>
          </template>
        </div>
        <div class="ds-question-error" v-if="!isDesign">
          <slot name="error">
            <span v-if="question.errors.length > 0" class="ds-error-text">{{ errorsMsg }}</span>
          </slot>
        </div>
        <div class="ds-question-remark" v-if="$showRemark">
          <template v-if="!isDesign">
            <div v-html="question.remark" :style="overStyle"></div>
            <div ref="back" class="back-div">{{ question.remark }}</div>
            <div class="show-more" v-if="isShowMore">
              <span v-if="!isOpen" @click="isOpen = true">展开 <i class="iconfont icon-shang1" /></span>
              <span v-if="isOpen" @click="isOpen = false">收起 <i class="iconfont icon-xia1" /></span>
            </div>
            <div class="title-description-mask" v-if="!isOpen && isShowMore"></div>
          </template>
          <template v-else>
            <DsfQuestionDesignerEditor
              style="background: rgb(247, 248, 250)"
              :value.sync="question.remark"
              :placeholder="'请输入题干说明'"
            ></DsfQuestionDesignerEditor>
          </template>
        </div>
        <div class="ds-question-answer">
          <slot name="answer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DsfAsqQuestionBody',
  data() {
    return {
      focus: {
        stem: false,
        remark: false
      },
      isShowMore: false,
      isOpen: false
    }
  },
  props: {
    showIndex: {
      type: Boolean,
      default: true
    },
    question: {
      type: Object,
      default() {
        return {}
      }
    },
    overHeight: {
      type: Number,
      default: 120
    }
  },
  computed: {
    overStyle() {
      return !this.isOpen && this.isShowMore
        ? {
            height: `${this.overHeight}px`,
            overflow: 'hidden'
          }
        : {}
    },
    isPreView() {
      return this.$route.query.isPreView
    },
    errorsMsg() {
      return _.map(this.question.errors, 'message').join(',')
    },
    isDesign() {
      return this.question.isDesign
    },
    $showRemark() {
      if (this.isDesign && this.question.showRemark) {
        return true
      } else if (!this.isDesign && this.question.showRemark && this.question.remark) {
        return true
      }
      return false
    },
    $showIndex() {
      if (!this?.question?.$page?.showQuestionIndex) {
        return false
      } else {
        return this.showIndex
      }
    }
  },
  mounted() {
    if (this.$refs.back) {
      this.$nextTick(() => {
        let h = this.$refs.back.offsetHeight
        if (h > this.overHeight) {
          this.isShowMore = true
        }
      })
    }
  },

  methods: {},
  watch: {}
}
</script>
