<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-14 14:24:02
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-16 17:11:38
-->
<template>
  <DsfAsqQuestionBody class="ds-question-imageselect" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-img-select-box pc">
        <img-select-item v-for="item in $items" :key="item.value" :item="item" :img-list="imgList" />
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>

<script>
import ImgSelectItem from './items/imgSelectItem'

export default dsf.component({
  name: 'DsfAsqQuestionImgSelect',
  ctrlCaption: '图片选择',
  mixins: [$mixins.asqQuestionItemsMixins],
  provide() {
    return {
      $imgSelect: this
    }
  },
  components: {
    ImgSelectItem
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    stem: {
      type: String,
      default: '图片选择'
    }
  },
  computed: {
    imgList() {
      return _.map(this.$items, 'imageSrc')
    }
  },
  methods: {
    onSelect(item, v) {
      let list = _.clone(this.selectValue)
      let val = item.value
      if (!this.multiple) {
        val = v ? val : null
      } else if (v) {
        list.push(val)
        val = list
      } else {
        dsf.array.remove(list, val)
        val = list
      }
      this.selectValue = val
    }
  }
})
</script>
