/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-01-18 18:04:43
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-23 10:21:12
 */
import color from './color'
function created (data,names) {
	return {
		"series": [
			{
				"type": "pie",
					minAngle: 15,//最小角度
					startAngle:270, //起始角度
				"center": [
					"50%",
					"50%"
				],
				"radius": [
					"0%",
					"70%"
				],
				"avoidLabelOverlap": true,
				"roseType": false,
				"label": {
					"show": true,
					"fontSize": 12,
					"position": "outside",
					"formatter": function (item) {
						return item.value+'%'
					},
				},
				"labelLine": {
					"show": true
				},
				itemStyle: { // 饼图白色间隙
					borderWidth: 1,
					borderColor: "#fff",
				},
				"data": data
			}
		],
		color,
		"title": {
			"show": false,
			"text": "",
			"subtext": "",
			"itemGap": 10,
			"left": "auto",
			"top": "auto",
			"right": "auto",
			"bottom": "auto",
			"textStyle": {
				"color": "#333",
				"fontSize": 18
			}
		},
		"legend": {
			"show": true,
			"left": "70%",
			"top": "auto",
			"right": "auto",
			"bottom": "50",
			"orient": "vertical",
			"icon": "circle",
			"data": names,
		},
		"grid": {
			"left": 30,
			"top": 60,
			"right": 30,
			"bottom": 30,
			"containLabel": true,
			"backgroundColor": "transparent",
			"borderColor": "#ccc",
			"borderWidth": 1,
			"show": false
		}
	}
}
export default created