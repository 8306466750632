var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-control ds-page" }, [
    _c("div", { staticClass: "statistics-wrap" }, [
      _c("div", { staticClass: "statistics-head" }, [
        _vm._v(_vm._s(_vm.asqName)),
      ]),
      _c("div", { staticClass: "statistics-content" }, [
        _c("div", { staticClass: "statistics-left" }, [
          _c("p", [_vm._v("数据概况")]),
          _c("div", { staticClass: "data-list" }, [
            _c("div", { staticClass: "list-item list-item-01" }, [
              _c("p", [_vm._v("今日新增")]),
              _c("p", [_vm._v(_vm._s(_vm.todaySubmitNum))]),
            ]),
            _c("div", { staticClass: "list-item list-item-02" }, [
              _c("p", [_vm._v("数据总量")]),
              _c("p", [_vm._v(_vm._s(_vm.submitNum))]),
            ]),
            _c("div", { staticClass: "list-item list-item-03" }, [
              _c("p", [_vm._v("平均答题时长")]),
              _c("p", [_vm._v(_vm._s(_vm.submitAvgTime))]),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "statistics-right" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "statistics-right-title",
                model: {
                  value: _vm.active.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.active, "value", $$v)
                  },
                  expression: "active.value",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "统计报表", name: "1" } },
                  [
                    _c("DsfAsqQuestionReportForm", {
                      attrs: { isDesign: _vm.isDesign },
                      on: { getData: _vm.getData },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "数据汇总", name: "2" } },
                  [
                    _c("DsfAsqQuestionDataCollect", {
                      attrs: { isDesign: _vm.isDesign },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }