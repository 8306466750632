var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-imageselect",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "ds-img-select-box pc" },
              _vm._l(_vm.$items, function (item) {
                return _c("img-select-item", {
                  key: item.value,
                  attrs: { item: item, "img-list": _vm.imgList },
                })
              }),
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }