var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "8px", "text-align": "center" } },
    [
      _vm.isReadOnly && !_vm.isPreView
        ? _c(
            "DsfButton",
            {
              attrs: { icon: "guanbi2", type: "plain" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v(" 关闭 ")]
          )
        : _c(
            "DsfButton",
            {
              attrs: { icon: "baocun", disabled: _vm.isPreView },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("提交")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }