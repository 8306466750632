/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-01-18 18:13:59
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-16 15:18:45
 */
import color from './color'
function created(value, xdata, multitude,type) {
	let series = []
	if (multitude) {
		value.forEach(it => {
			series.push({
				"type": "line",
				"name": it.name,
				"yAxisIndex": 0,
				"xAxisIndex": 0,
				"symbol": "circle",
				"symbolSize": "5",
				"showSymbol": true,
				"label": {
					"show": true,
					"position": "top",
					"formatter": function (item) {
						return type==='dsf.asqquestionscore'? item.data + '分':item.data + '%'
					},
					"color": "#333",

				},
				"smooth": false,
				"areaStyle": {
					"color": "rgba(255,255,255,0)",
				},
				"lineStyle": {
					"width": "1",
					"type": "solid"
				},
				"data": it.value
			})
		});
	} else {
		series = [
			{
				"type": "line",
				"yAxisIndex": 0,
				"xAxisIndex": 0,
				"symbol": "circle",
				"symbolSize": "5",
				"showSymbol": true,
				"label": {
					"show": true,
					"position": "top",
					"formatter": function (item) {
						return item.data + (type==='dsf.asqquestionorder'?'':'%')
					},
					"color": "#333",

				},
				"smooth": false,
				"areaStyle": {
					"color": "rgba(255,255,255,0)",
				},
				"lineStyle": {
					"width": "1",
					"type": "solid"
				},
				"data": value
			}
		]
	}
	return {
		"series": series,
		color,
		"title": {
			"show": false,
			"subtext": "",
			"itemGap": 10,
			"left": "auto",
			"top": "auto",
			"right": "auto",
			"bottom": "auto",
			"textStyle": {
				"color": "#333",
				"fontSize": 18
			}
		},
		"legend": {
			"show": multitude ? true : false,
			"left": "auto",
			"top": "auto",
			"right": "30",
			"bottom": "50",
			"orient": "vertical",
			"icon": "circle",
		},
		"grid": {
			"width": "60%",
			"left": "center",
			"top": 30,
			"right": 10,
			"bottom": 30,
			"containLabel": true,
			"backgroundColor": "transparent",
			"borderColor": "#ccc",
			"borderWidth": 1,
			"show": false
		},
		"xAxis": [
			{
				"type": "category",
				"axisLine": {
					"show": true,
					"lineStyle": {
						"color": "#F0F2F5"
					}
				},
				"axisTick": {
					"show": true,
					"alignWithLabel": false
				},
				"axisLabel": {
					"show": true,
					"fontSize": 12,
					interval:0,
					"color": "#333"
				},
				"splitLine": {
					"show": false
				},
				"name": "",
				"nameLocation": "center",
				"nameTextStyle": {
					"color": "#333",
					"fontSize": 12
				},
				"nameGap": 30,
				"boundaryGap": true,
				"position": "bottom",
				"interval": 0,
				"data": xdata
			}
		],
		"yAxis": [
			{
				"type": "value",
				"axisLine": {
					"show": false,
					"lineStyle": {
						"color": "#333"
					}
				},
				"axisTick": {
					"show": false
				},
				"axisLabel": {
					"show": true,
					"fontSize": 12,
					"rotate": 0,
					"color": "#999999"
				},
				"splitLine": {
					"show": true,
					"lineStyle": {
						"color": "#F0F2F5"
					}
				},
				"name": "",
				"nameLocation": "end",
				"nameTextStyle": {
					"color": "#333",
					"fontSize": 12
				},
				"nameGap": 18,
				"position": "left",
				"min": 0,
				"max": (multitude||type==='dsf.asqquestionorder') ? 'dataMax' : 100,
			}
		]
	}
}
export default created