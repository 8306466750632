var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-order",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c("div", { staticClass: "ds-question-order-options" }, [
              _vm.leftItems.length > 0
                ? _c("div", { staticClass: "origin-data-list" }, [
                    _c(
                      "div",
                      { staticClass: "list-box" },
                      _vm._l(_vm.leftItems, function (item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "span",
                            {
                              attrs: {
                                draggable:
                                  (item.value ? true : false) &&
                                  !_vm.readOnly &&
                                  !_vm.disabled,
                              },
                              on: {
                                dragstart: function ($event) {
                                  return _vm.startDrag($event, index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          ),
                        ])
                      }),
                      0
                    ),
                    _c("p", { staticClass: "ds-question-order-tip" }, [
                      _vm._v(
                        "请将左面的项拖放到右面的框完成排序，若想更换顺序，请上下拖动"
                      ),
                    ]),
                  ])
                : _vm._e(),
              !_vm.isDesign && _vm.leftItems.length > 0 && _vm.orderNumber > 0
                ? _c(
                    "div",
                    { staticClass: "transform-box" },
                    _vm._l(_vm.orderList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "li",
                          class: {
                            "boder-bottom": index === _vm.orderList.length - 1,
                          },
                        },
                        [
                          _c("span", { staticClass: "order" }, [
                            _vm._v(_vm._s(index + 1)),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "value",
                              class: { active: index + 1 == _vm.active },
                              attrs: {
                                "data-index": index + 1,
                                draggable: !_vm.readOnly && !_vm.disabled,
                              },
                              on: {
                                dragover: function ($event) {
                                  $event.preventDefault()
                                },
                                drop: function ($event) {
                                  return _vm.drop($event, index)
                                },
                                dragleave: function ($event) {
                                  return _vm.dragleave($event, index + 1)
                                },
                                dragenter: function ($event) {
                                  return _vm.dragenter($event)
                                },
                                dragstart: function ($event) {
                                  return _vm.startDragTransform($event, index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }