var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-fill",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "fill-content",
                class: { isDesign: _vm.isDesign },
              },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    autosize: _vm.isDesign ? false : { minRows: _vm.rows },
                    rows: _vm.rows,
                    placeholder: _vm.placeholder,
                    readonly: _vm.isDesign ? true : _vm.readOnly,
                    disabled: _vm.disabled,
                    clearable: _vm.clearable,
                    resize: _vm.resizeState,
                    maxlength: _vm.maxlength > 0 ? _vm.maxlength : false,
                    showWordLimit:
                      _vm.maxlength > 0 ? _vm.showWordLimit : false,
                  },
                  model: {
                    value: _vm._value,
                    callback: function ($$v) {
                      _vm._value = $$v
                    },
                    expression: "_value",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }