var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-score",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "ds-question-options" },
              [
                _vm._l(_vm.valueCopy, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "ds-question-options-item" },
                    [
                      _vm.valueCopy.length > 1
                        ? _c(
                            "div",
                            { staticClass: "sub-title" },
                            [
                              !_vm.isDesign
                                ? [
                                    _c("div", {
                                      staticClass:
                                        "ds-question-options-item-text",
                                      domProps: {
                                        innerHTML: _vm._s(item.text),
                                      },
                                    }),
                                  ]
                                : [
                                    _c("DsfQuestionDesignerEditor", {
                                      attrs: {
                                        value: item.text,
                                        placeholder: "请输入题干",
                                        "empty-use-placeholder": "",
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(item, "text", $event)
                                        },
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.showStyle !== "slider"
                        ? _c("el-rate", {
                            attrs: {
                              value: item.score - _vm.min + 1,
                              max: _vm.max - _vm.min + 1,
                              "icon-classes": _vm.curIcon,
                              colors: _vm.colors,
                              disabled: _vm.readOnly || _vm.disabled,
                              "void-icon-class": _vm.voidClass,
                              "disabled-void-icon-class": _vm.voidClass,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changeScore($event, index)
                              },
                            },
                          })
                        : _c("el-slider", {
                            ref: "slider1",
                            refInFor: true,
                            attrs: {
                              min: _vm.min,
                              max: _vm.max,
                              step: 1,
                              disabled: _vm.readOnly || _vm.disabled,
                              "show-tooltip": "",
                              "format-tooltip": _vm.formatTooltip,
                              "show-stops": "",
                            },
                            model: {
                              value: item.score,
                              callback: function ($$v) {
                                _vm.$set(item, "score", $$v)
                              },
                              expression: "item.score",
                            },
                          }),
                    ],
                    1
                  )
                }),
                _vm.viewLeft || _vm.viewCenter || _vm.viewRight
                  ? _c(
                      "div",
                      { staticClass: "ds-question-options-item view" },
                      [
                        _c("span", { staticClass: "left" }, [
                          _vm._v(_vm._s(_vm.viewLeft)),
                        ]),
                        _c("span", { staticClass: "center" }, [
                          _vm._v(_vm._s(_vm.viewCenter)),
                        ]),
                        _c("span", { staticClass: "right" }, [
                          _vm._v(_vm._s(_vm.viewRight)),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }