var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ds-control ds-question-item" }, [
    !_vm.question.logicIgnore
      ? _c("div", { staticClass: "ds-question-item-wrap" }, [
          _c("div", { staticClass: "ds-question-index" }, [
            _c("span", { staticClass: "required" }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$showIndex,
                    expression: "$showIndex",
                  },
                ],
                staticClass: "ds-question-index-num",
              },
              [_vm._v(_vm._s(_vm.question.$questionIndex))]
            ),
          ]),
          _c("div", { staticClass: "ds-question-content" }, [
            _c(
              "div",
              { staticClass: "ds-question-stem" },
              [
                !_vm.isDesign
                  ? [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.question.stem) },
                      }),
                    ]
                  : [
                      _c("DsfQuestionDesignerEditor", {
                        attrs: {
                          value: _vm.question.stem,
                          placeholder: "请输入题干",
                          "empty-use-placeholder": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.question, "stem", $event)
                          },
                        },
                      }),
                    ],
              ],
              2
            ),
            !_vm.isDesign
              ? _c(
                  "div",
                  { staticClass: "ds-question-error" },
                  [
                    _vm._t("error", function () {
                      return [
                        _vm.question.errors.length > 0
                          ? _c("span", { staticClass: "ds-error-text" }, [
                              _vm._v(_vm._s(_vm.errorsMsg)),
                            ])
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.$showRemark
              ? _c(
                  "div",
                  { staticClass: "ds-question-remark" },
                  [
                    !_vm.isDesign
                      ? [
                          _c("div", {
                            style: _vm.overStyle,
                            domProps: {
                              innerHTML: _vm._s(_vm.question.remark),
                            },
                          }),
                          _c("div", { ref: "back", staticClass: "back-div" }, [
                            _vm._v(_vm._s(_vm.question.remark)),
                          ]),
                          _vm.isShowMore
                            ? _c("div", { staticClass: "show-more" }, [
                                !_vm.isOpen
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.isOpen = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v("展开 "),
                                        _c("i", {
                                          staticClass: "iconfont icon-shang1",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isOpen
                                  ? _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            _vm.isOpen = false
                                          },
                                        },
                                      },
                                      [
                                        _vm._v("收起 "),
                                        _c("i", {
                                          staticClass: "iconfont icon-xia1",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          !_vm.isOpen && _vm.isShowMore
                            ? _c("div", {
                                staticClass: "title-description-mask",
                              })
                            : _vm._e(),
                        ]
                      : [
                          _c("DsfQuestionDesignerEditor", {
                            staticStyle: { background: "rgb(247, 248, 250)" },
                            attrs: {
                              value: _vm.question.remark,
                              placeholder: "请输入题干说明",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.question, "remark", $event)
                              },
                            },
                          }),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "ds-question-answer" },
              [_vm._t("answer")],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }