var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-signature",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "signature" },
              [
                _vm.isDesign || _vm.readOnly || _vm.disabled
                  ? [
                      _vm.$value
                        ? _c("img", {
                            staticClass: "save-img",
                            attrs: { src: _vm.$value },
                          })
                        : _c("canvas"),
                      !_vm.$value
                        ? _c("span", { staticClass: "signature-tip" }, [
                            _vm._v("请在此处签名"),
                          ])
                        : _vm._e(),
                    ]
                  : [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$value,
                            expression: "$value",
                          },
                        ],
                        staticClass: "save-img",
                        attrs: { src: _vm.$value },
                      }),
                      _c("canvas", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$value,
                            expression: "!$value",
                          },
                        ],
                        ref: "canvas",
                        on: { mousedown: _vm.focus },
                      }),
                      !_vm.isFocus && !_vm.$value
                        ? _c("span", { staticClass: "signature-tip" }, [
                            _vm._v("请在此处签名"),
                          ])
                        : _vm._e(),
                      !_vm.readOnly && !_vm.disabled && _vm.$value
                        ? _c("div", { staticClass: "save-signature" }, [
                            _vm._v(
                              " 签名已保存，如需重新签名，请点击【重签】按钮 "
                            ),
                            _c(
                              "div",
                              { staticClass: "operation" },
                              [
                                _c(
                                  "DsfButton",
                                  {
                                    staticClass: "save-btn",
                                    attrs: { type: "plain" },
                                    on: { click: _vm.reset },
                                  },
                                  [_vm._v(" 重签 ")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("div", { staticClass: "save-signature" }, [
                            _vm._v(" 完成签名后，请点击【完成】按钮 "),
                            !_vm.isFocus
                              ? _c("span", { staticClass: "save" }, [
                                  _vm._v("完成"),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "operation" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "clear",
                                        on: { click: _vm.clear },
                                      },
                                      [_vm._v("清空")]
                                    ),
                                    _c(
                                      "DsfButton",
                                      {
                                        staticClass: "save-btn",
                                        attrs: { type: "plain" },
                                        on: { click: _vm.getImg },
                                      },
                                      [_vm._v(" 完成 ")]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                    ],
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }