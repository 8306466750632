<!--
 * @Descripttion: 排序题
 * @Author: zhanghang
 * @Date: 2021-12-07 11:25:46
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-21 16:56:03
-->
<template>
  <DsfAsqQuestionBody class="ds-question-order" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-question-order-options">
        <div class="origin-data-list" v-if="leftItems.length > 0">
          <div class="list-box">
            <div v-for="(item, index) in leftItems" :key="index">
              <span :draggable="(item.value ? true : false) && !readOnly && !disabled" @dragstart="startDrag($event, index)">
                {{ item.text }}
              </span>
            </div>
          </div>
          <p class="ds-question-order-tip">请将左面的项拖放到右面的框完成排序，若想更换顺序，请上下拖动</p>
        </div>
        <div class="transform-box" v-if="!isDesign && leftItems.length > 0 && orderNumber > 0">
          <div class="li" :class="{ 'boder-bottom': index === orderList.length - 1 }" v-for="(item, index) in orderList" :key="index">
            <span class="order">{{ index + 1 }}</span>
            <span
              class="value"
              :class="{ active: index + 1 == active }"
              :data-index="index + 1"
              @dragover.prevent
              @drop="drop($event, index)"
              @dragleave="dragleave($event, index + 1)"
              @dragenter="dragenter($event)"
              :draggable="!readOnly && !disabled"
              @dragstart="startDragTransform($event, index)"
            >
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionOrder',
  ctrlCaption: '排序题',
  mixins: [$mixins.asqQuestionControl, $mixins.asqQuestionDefaultOptions],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    stem: {
      type: String,
      default: '排序题'
    },
    metadata: {
      type: Object,
      default() {
        return dsf.metadata.get('question-order-item-meta-data')
      }
    },
    orderNumber: {
      type: Number,
      default: 1
    },
    //是否乱序
    isShuffle: {
      type: Boolean,
      default: false
    },
    fixationEnd: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array],
      default() {
        return []
      }
    },
    controlMode: {
      type: String,
      default: 'order'
    }
  },
  data() {
    return {
      leftItems: [],
      orderList: [],
      active: '',
      shuffleItems: [],
      $items: []
    }
  },
  created() {
    this.init()
  },
  watch: {
    items: {
      handler(v) {
        if (this.isDesign) {
          this.orderNumber = v.length
          this.init()
        }
      },
      deep: true
    },
    value: {
      handler(value) {
        value && value.length && this.updateOriginData(value)
      },
      deep: true
    }
  },
  methods: {
    $formItemInnerValidate() {
      let errors = []
      if (this.required && this.orderList.filter((it) => !it.value).length) {
        errors.push({ message: '请对所有选项进行排序' })
      }
      return errors
    },
    updateValue() {
      let realvalue = []
      this.orderList.forEach((it, index) => {
        let { text, value } = it
        it.value && realvalue.push({ text, value, ds_order: index })
      })
      this.emitValueChange(realvalue)
    },
    init() {
      this.$items = this.items.map((it) => {
        return {
          value: it.value + '',
          text: it.text
        }
      })
      let num = this.orderNumber > this.$items.length ? this.$items.length : this.orderNumber

      if (this.isShuffle && !this.isDesign) {
        let items = _.cloneDeep(this.$items)
        if (this.fixationEnd) {
          items = [..._.shuffle(items.slice(0, items.length - 1)), ...items.slice(items.length - 1)]
        } else {
          items = _.shuffle(items)
        }
        this.shuffleItems = items
      }
      this.leftItems = _.cloneDeep(this.$items)
      //构造排序数组
      this.orderList = new Array(num).fill({
        order: '',
        value: '',
        text: ''
      })
    },
    updateOriginData(value) {
      let items = this.isShuffle ? this.shuffleItems : this.$items
      value.forEach((it) => {
        let index = it.ds_order

        let order = items.findIndex((_it) => _it.value === it.value)
        this.orderList[index] = {
          order: order,
          value: it.value,
          text: it.text
        }
        let _index = this.leftItems.findIndex((_it) => _it.value === it.value)

        if (_index > -1) {
          let item = this.leftItems[_index]
          item.text = ''
          item.value = ''
        }
      })
    },
    startDrag($event, index) {
      $event.dataTransfer.setData('index', index)
    },
    startDragTransform($event, index) {
      $event.dataTransfer.setData('orderIndex', index)
    },
    dragenter($event) {
      const target = $event.target.dataset.index
      if (target) {
        this.active = target
      }
    },
    dragleave($event, n) {
      if (this.active === String(n)) {
        this.active = ''
      }
    },
    drop($event, targetIndex) {
      this.active = ''
      const index = $event.dataTransfer.getData('index')
      const orderIndex = $event.dataTransfer.getData('orderIndex')

      if (index !== '') {
        // 如果存在
        if (this.orderList[targetIndex].text) {
          this.$set(this.leftItems, this.orderList[targetIndex].order, {
            value: this.orderList[targetIndex].value,
            text: this.orderList[targetIndex].text
          })
        }
        this.$set(this.orderList, targetIndex, {
          order: index,
          value: this.leftItems[index].value,
          text: this.leftItems[index].text
        })
        this.leftItems[index].text = ''
        this.leftItems[index].value = ''
      }
      // 目标数组自己调换
      if (orderIndex !== '') {
        const orderList = this.orderList
        ;[orderList[orderIndex], orderList[targetIndex]] = [orderList[targetIndex], orderList[orderIndex]]
      }
      this.updateValue()
    }
  }
})
</script>
