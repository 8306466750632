var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DsfAsqQuestionBody", {
    staticClass: "ds-question-horzfill",
    class: _vm.getCss,
    attrs: { "show-index": _vm.showIndex, question: _vm._self },
    scopedSlots: _vm._u([
      {
        key: "answer",
        fn: function () {
          return [
            _vm.isDesign
              ? _c("div", {
                  staticClass:
                    "designer-question-editor designer-question-editor-focus",
                  attrs: { contenteditable: "true" },
                  domProps: { innerHTML: _vm._s(_vm._content) },
                  on: {
                    focus: function ($event) {
                      return _vm.focusHandler($event)
                    },
                    blur: function ($event) {
                      return _vm.blurHandler($event)
                    },
                  },
                })
              : _vm._e(),
            _vm.isDesign
              ? _c("span", { staticClass: "fontsize" }, [
                  _vm._v(" 注：输入中文的（） 则为插入输入框 "),
                ])
              : _c(
                  "div",
                  { ref: "box", staticClass: "ds-horzfill-content" },
                  [
                    _vm._l(_vm.contentArray, function (c, i) {
                      return [
                        c.text
                          ? _c("span", {
                              key: "span" + i,
                              domProps: { innerHTML: _vm._s(c.text) },
                            })
                          : [
                              _c("span", {
                                key: "span" + i,
                                domProps: { innerHTML: _vm._s(c) },
                              }),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.value2["fill" + (i + 1)],
                                    expression: "value2['fill' + (i + 1)]",
                                  },
                                ],
                                key: "input" + i,
                                ref: "fill" + (i + 1),
                                refInFor: true,
                                staticClass: "horzfill-input",
                                attrs: {
                                  readonly: _vm.readOnly,
                                  disabled: _vm.disabled,
                                },
                                domProps: {
                                  value: _vm.value2["fill" + (i + 1)],
                                },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.value2,
                                        "fill" + (i + 1),
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      _vm.inputHandler("fill" + (i + 1))
                                    },
                                  ],
                                },
                              }),
                            ],
                      ]
                    }),
                  ],
                  2
                ),
            _c("span", { ref: "fontsize", staticClass: "fontsize" }, [
              _vm._v(_vm._s(_vm.fontsize)),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }