/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-06 13:50:46
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-04-07 10:30:15
 */
export default {
  async system_question_save(args){
    let vm = args._self
    let form = vm.$form;
    if (form) {
      let options = {
        isRefresh:true, //是否要刷新父页面
        customParams: args.params[1] || "",
        savedClose: args.params[2] === true,
        successMsg: args.params[3] || "保存成功",
        errorMsg: args.params[4] || "保存失败"
      };
      if (!form.$isPrototype) {
        await dsf.layer.confirm({
          message:"是否确定提交？"
        })
        
        form.$set(form.$viewData,'use_time',vm.use_time)
        let result =  await form.formSave(options);
        if(result){
          let params = dsf.mix({}, vm.$route.query, {
            _id:result.data._id,
            asqInfoId:result.data.asq_info_id,
            scope_value:result.data.scope_value //问卷类型，公开：0
          });
          //成功页面
          vm.$router.replace({
            path: '/pc/asq/info/submitsuccess',
            query: params
          });

        }
      }

      else {
        dsf.layer.message(options.successMsg)
      }
    }
  }
}