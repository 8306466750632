<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 16:27:05
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-12 10:09:00
-->
<template>
  <DsfAsqQuestionBody class="ds-question-checkbox" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-question-options" :class="['layout--'+optionsLayout]">
        <el-checkbox-group v-model="selectValue" class="el-row el-row--flex" :disabled="readOnly || disabled">
          <el-checkbox
            v-for="item in $items"
            :key="item.value"
            :class="['el-col', 'el-col-'+(24/optionsLayout)]"
            :label="item.value"
            :disabled="item.disabled">
            {{item.text}}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
export default dsf.component({
  name: "DsfAsqQuestionCheckbox",
  ctrlCaption: "多选题",
  mixins: [$mixins.asqQuestionItemsMixins],
  data() {
    return {
      multiple: true
    };
  }
});
</script>