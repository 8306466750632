<!--
 * @Descripttion: 电子签名
 * @Author: zhanghang
 * @Date: 2021-12-09 16:40:13
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-21 16:57:18
-->
<template>
  <DsfAsqQuestionBody class="ds-question-signature" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="signature">
        <template v-if="isDesign || readOnly || disabled">
          <img class="save-img" :src="$value" v-if="$value" />
          <canvas v-else></canvas>
          <span class="signature-tip" v-if="!$value">请在此处签名</span>
        </template>
        <template v-else>
          <img class="save-img" :src="$value" v-show="$value" />
          <canvas ref="canvas" @mousedown="focus" v-show="!$value"></canvas>
          <span class="signature-tip" v-if="!isFocus && !$value">请在此处签名</span>
          <div class="save-signature" v-if="!readOnly && !disabled && $value">
            签名已保存，如需重新签名，请点击【重签】按钮
            <div class="operation">
              <DsfButton class="save-btn" type="plain" @click="reset"> 重签 </DsfButton>
            </div>
          </div>
          <div class="save-signature" v-else>
            完成签名后，请点击【完成】按钮
            <span class="save" v-if="!isFocus">完成</span>
            <div class="operation" v-else>
              <span class="clear" @click="clear">清空</span>
              <DsfButton class="save-btn" type="plain" @click="getImg"> 完成 </DsfButton>
            </div>
          </div>
        </template>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
const base64ToBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
export default dsf.component({
  name: 'DsfAsqQuestionSignature',
  ctrlCaption: '电子签名',
  mixins: [$mixins.asqQuestionControl],
  props: {
    metadata: {
      type: Object,
      default() {
        let md = dsf.metadata.get('question-signature-item-meta-data')
        return md
      }
    },
    stem: {
      type: String,
      default: '电子签名'
    },
    controlMode: {
      type: String,
      default: 'signature'
    }
  },
  data() {
    return {
      //上传地址
      upFileUrl: 'file/upload/',
      signature: null,
      isFocus: false
    }
  },
  mounted() {
    this.loadScript(() => {
      this.initCanvas()
    })
  },
  computed: {
    $value() {
      let files = this.value ? JSON.parse(this.value) : []
      return dsf.url.getWebPath(files?.[0]?.relativePath)
    }
  },
  methods: {
    reset() {
      this.clear()
      this.isFocus = false
      this.emitValueChange('')

      this.$nextTick(() => {
        this.initCanvas()
      })
    },
    initCanvas() {
      const canvas = this.$refs.canvas
      if (canvas) this.signature = new SmoothSignature(canvas)
    },
    focus() {
      this.isFocus = true
    },
    clear() {
      this.signature && this.signature.clear()
    },
    getImg() {
      if (this.signature) {
        if (this.signature.isEmpty()) {
          dsf.layer.message('请写入内容', false)
          return false
        }
        //将图片上传
        this.upload(this.signature.getPNG())
      }
    },
    //签名上传
    upload(base64) {
      return new Promise((resolve, reject) => {
        let blob = base64ToBlob(base64.split(',')[1], 'image/png')
        blob.name = dsf.uuid(32) + '.png'
        this.$http
          .upload(this.upFileUrl, {
            files: blob
          })
          .done(({ success, data, message }) => {
            if (!success) {
              dsf.layer.message(message || '上传签名失败', false)
              reject(message)
              return
            }
            this.emitValueChange(JSON.stringify(data))
            this.$nextTick(() => {
              resolve()
            })
          })
          .error((err) => {
            dsf.layer.message('上传签名失败发生未知异常', false)
            reject(err)
          })
      })
    },
    loadScript(callback) {
      if (window.SmoothSignature) {
        callback?.()
      } else {
        this.$http
          .importFiles([dsf.url.getWebPath('$/js/libs/smooth-signature/smooth-signature.min.js')])
          .then(() => {
            callback?.()
          })
          .catch((err) => {
            console.error(err)
            dsf.layer.message('加载signature文件报错', false)
          })
      }
    }
  }
})
</script>
