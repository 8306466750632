var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ds-img-select-item", on: { click: _vm.itemClick } },
    [
      _c(
        "div",
        {
          staticClass: "ds-img-select-item-img",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("dsf-image", {
            attrs: {
              src: _vm.item.imageSrc,
              "preview-src-list": _vm.$imgSelect.imgList,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ds-img-select-item-title" },
        [
          _vm.$imgSelect.multiple
            ? _c("el-checkbox", {
                attrs: {
                  disabled: _vm.$imgSelect.readOnly || _vm.$imgSelect.disabled,
                },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.select,
                  callback: function ($$v) {
                    _vm.select = $$v
                  },
                  expression: "select",
                },
              })
            : _c("el-radio", {
                attrs: {
                  label: true,
                  disabled: _vm.$imgSelect.readOnly || _vm.$imgSelect.disabled,
                },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.select,
                  callback: function ($$v) {
                    _vm.select = $$v
                  },
                  expression: "select",
                },
              }),
          _c("span", [_vm._v(_vm._s(_vm.item.text))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }