/*
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2022-01-18 18:21:38
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-01-25 17:33:24
 */
import color from './color'
function created(value, xdata, multitude,type) {
	let series = []
	if (multitude) {
		value.forEach(it => {
			series.push({
				"name": it.name,
				"type": "bar",
				"yAxisIndex": 0,
				"xAxisIndex": 0,
				"label": {
					"show": true,
					"position": "right",
					"formatter": function (item) {
						return type==='dsf.asqquestionscore'? item.data + '分':item.data + '%'
					},
					"color": "#333",
				},
				"barGap": "30%",
				"barCategoryGap": "10%",
				"barWidth": "20",
				"showBackground": false,
				"data": it.value

			})
		});
	} else {
		series = [
			{
				"type": "bar",
				"yAxisIndex": 0,
				"xAxisIndex": 0,
				"label": {
					"show": true,
					"position": "right",
					"formatter": function (item) {
						return item.data + (type==='dsf.asqquestionorder'?'':'%')
					},
					"color": "#333",
				},
				"barGap": "30%",
				"barCategoryGap": "10%",
				"barWidth": "20",
				"showBackground": false,
				"data": value
			}
		]
	}

	return {
		"series": series,
		color,
		"title": {
			"show": false,
			"subtext": "",
			"itemGap": 10,
			"left": "auto",
			"top": "auto",
			"right": "auto",
			"bottom": "auto",
			"textStyle": {
				"color": "#333",
				"fontSize": 18
			}
		},
		"legend": {
			"show": multitude?true:false,
			"left": "auto",
			"top": "auto",
			"right": "30",
			"bottom": "50",
			"orient": "vertical",
			"icon": "circle",
		},
		"grid": {
			"width": "60%",
			"left": "center",
			"top": 20,
			"right": 30,
			"bottom": 30,
			"containLabel": true,
			"backgroundColor": "transparent",
			"borderColor": "#ccc",
			"borderWidth": 1,
			"show": false
		},
		"xAxis": [
			{
				"type": "value",
				"axisLine": {
					"show": true,
					"lineStyle": {
						"color": "#D8D8D8"
					}
				},
				"axisTick": {
					"show": false,
					"alignWithLabel": true
				},
				"axisLabel": {
					"show": true,
					"fontSize": 12,
					"rotate": 0,
					"color": "#333"
				},
				"splitLine": {
					"show": false
				},
				"name": "",
				"nameLocation": "end",
				"nameTextStyle": {
					"color": "#333",
					"fontSize": 12
				},
				"nameGap": 18,
				"position": "bottom",
				"min": 0,
				"max": (multitude||type==='dsf.asqquestionorder') ? 'dataMax' : 100,
				"boundaryGap": true
			}
		],
		"yAxis": [
			{
				"type": "category",
				"axisLine": {
					"show": true,
					"lineStyle": {
						"color": "#E4E7ED"
					}
				},
				"axisTick": {
					"show": true,
					"alignWithLabel": false
				},
				"axisLabel": {
					"show": true,
					"fontSize": 12,
					"rotate": 0,
					"color": "#333"
				},
				"splitLine": {
					"show": false
				},
				"name": "",
				"nameLocation": "center",
				"nameTextStyle": {
					"color": "#333",
					"fontSize": 12
				},
				"nameGap": "0",
				"boundaryGap": true,
				"position": "left",
				"interval": 0,
				"data": xdata
			}
		]
	}
}
export default created