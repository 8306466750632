<!--
 * @Descripttion: 
 * @Author: zhanghang
 * @Date: 2021-12-16 14:45:47
 * @LastEditors: zhanghang
 * @LastEditTime: 2022-02-18 14:18:47
-->
<template>
  <DsfAsqQuestionBody class="ds-question-score" :show-index="showIndex" :class="getCss" :question="_self">
    <template v-slot:answer>
      <div class="ds-question-options">
        <div class="ds-question-options-item" v-for="(item, index) in valueCopy" :key="index">
          <div class="sub-title" v-if="valueCopy.length > 1">
            <template v-if="!isDesign">
              <div v-html="item.text" class="ds-question-options-item-text"></div>
            </template>
            <template v-else>
              <DsfQuestionDesignerEditor :value.sync="item.text" :placeholder="'请输入题干'" empty-use-placeholder></DsfQuestionDesignerEditor>
            </template>
          </div>
          <el-rate
            v-if="showStyle !== 'slider'"
            :value="(item.score-min + 1)"
            :max="max - min + 1"
            :icon-classes="curIcon"
            :colors="colors"
            :disabled="readOnly || disabled"
            :void-icon-class="voidClass"
            :disabled-void-icon-class="voidClass"
            @change="changeScore($event,index)"
          >
          </el-rate>
          <el-slider
            ref="slider1"
            v-else
            v-model="item.score"
            :min="min"
            :max="max"
            :step="1"
            :disabled="readOnly || disabled"
            show-tooltip
            :format-tooltip="formatTooltip"
            show-stops
          >
          </el-slider>
        </div>
        <div v-if="viewLeft || viewCenter || viewRight" class="ds-question-options-item view">
          <span class="left">{{ viewLeft }}</span>
          <span class="center">{{ viewCenter }}</span>
          <span class="right">{{ viewRight }}</span>
        </div>
      </div>
    </template>
  </DsfAsqQuestionBody>
</template>
<script>
export default dsf.component({
  name: 'DsfAsqQuestionScore',
  ctrlCaption: '打分题',
  mixins: [$mixins.asqQuestionScore, $mixins.asqQuestionDefaultOptions],
  props: {
    stem: {
      type: String,
      default: '打分题'
    }
  }
})
</script>
